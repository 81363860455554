// IMPORTS
import 'bootstrap';

import ClipboardJS from 'clipboard';

// INIT CLIPBOARD
new ClipboardJS('.copy');

// FORM VALIDATION
(function () {
    'use strict'
  
    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    var forms = document.querySelectorAll('.needs-validation')
  
    // Loop over them and prevent submission
    Array.prototype.slice.call(forms)
      .forEach(function (form) {
        form.addEventListener('submit', function (event) {
          if (!form.checkValidity()) {
            event.preventDefault()
            event.stopPropagation()
          }
  
          form.classList.add('was-validated');

          if (form.checkValidity()) {
            var signature = document.getElementById("iframe-holder");
            signature.classList.remove("d-none");
          }
          
        }, false)
      })
  })()
